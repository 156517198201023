import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import Banner from "./banner"

export default () => (
	<>
        
		<Banner title="FAQ" link="/faq" />
        <div style={{margin:"50px auto", padding:"15px", textAlign:"justify", maxWidth:"700px"}}>
		{/*<div className="container">*/}

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              It seems I can do everything with the Free Beta plan. Why would I consider the Paid Beta plan?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
              We received a surge in demand and feature requests due to COVID-19
            and decided to prioritize helping all our users over collecting
            payment and putting restrictions in place. Our feature set for free
            users is still a moving target. Over the upcoming months, as we
            continue to improve our site, we will also at some point need to
            return to a more profit-sustaining business. We are asking
            professional users to join the paid beta plan.</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Why can’t I have more than 7 simultaneous editors on one board?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>At the moment, this is a technical limitation. We hope to eventually support
            more simultaneous editors.</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              What if I am already on an existing plan?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>You should have received an email from us. If you have not or have more questions, please contact us
            at contact@scratchwork.io.</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              What happened to the LaTeX pen?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>Due to COVID-19, we had a surge in free users which made this feature too
            costly to support. We hope to bring it back soon, especially once usage settles
            down and we can work out the economics of the feature.</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        
		</div>
	</>
);
