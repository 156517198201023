import React from "react"

import WebsiteLayout from '../components/site/website_layout'
import FAQ from '../components/site/faq'

export default () => (
  <WebsiteLayout>
    <FAQ />
  </WebsiteLayout>
);
